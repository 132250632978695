.topMapButtons {
    position: absolute;
    top: 5%;

    .topFeaturedProperties {
        background: #4E6F49;
        // background: #3e4c66;

        left: 10%;
        color: white;
        margin-inline: 0.8rem;
        padding: 0.2rem;
        border-radius: 2px;
        font-size: 0.7rem;
    }

    .topRentProperties {
        background: rgb(230, 184, 0);
        left: 20%;
        color: white;
        border-radius: 2px;
        padding: 0.2rem;
        font-size: 0.7rem;
    }
}

.topMapButtonsLast {
    position: absolute;
    top: 5%;
    right: 5%;

    .topFeaturedProperties {
        background: #4E6F49;
        // background: #3e4c66;

        left: 10%;
        color: white;
        margin-inline: 0.8rem;
        padding: 0.2rem;
        border-radius: 2px;
        font-size: 0.7rem;
    }

    .topRentProperties {
        background: rgb(230, 184, 0);
        left: 20%;
        margin-left: 0.8rem;
        color: white;
        border-radius: 2px;
        padding: 0.2rem;
        font-size: 0.7rem;
    }
}