/* breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  } */
.property {
    overflow-x: hidden;
    padding-top: 11rem;
    margin-inline: 2rem;
    // width: 90%;
    // float: right;
    // margin-right: 3rem;


    @media screen and (max-width: 768px) {
        padding: 0;
    }

    .topSelectedProperty {
        height: 33vh;
        border-radius: 1.5em;
        // margin-inline: 1rem;
        margin-inline: 4rem;
        margin-top: 0.5rem;
        // width: 98%;
        width: 90%;
        // padding: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;


        @media screen and (max-width: 768px) {
            margin-top: 9rem;
        }

        @media screen and (max-width: 800px) {
            margin-top: 15rem;
            margin-inline: 0rem;
            width: 100%;
        }
    }

    .topSelectedProperty::before {
        content: "";
        background-color: rgb(13 6 6 / 27%);
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 1.5rem;
        right: 0;
        top: 0;
        width: 100%;


    }

    .properties {
        color: white;
        padding: 1rem;
        position: relative;
        font-family: "Axiforma", sans-serif;
        font-size: 2.5rem;
        margin-left: 0 !important;
        font-weight: 900;
        text-transform: uppercase;
        text-shadow: 4px 1px 4px #000000;

    }

    span.properties {
        color: white;
        padding: 0rem;
        position: relative;

        font-family: "Axiforma", sans-serif;
        font-size: 1.5rem;
        margin-left: 0 !important;
        font-weight: 900;
        text-transform: uppercase;
        text-shadow: 4px 1px 4px #000000;


    }

    // .footer {

    //     // margin-left: 12rem;
    //     .lowerFooter {
    //         background-color: #4E6F49;
    //         padding-bottom: 30px;
    //         padding-top: 40px;
    //         display: flex;
    //         position: inherit;
    //         justify-content: space-around;
    //     }
    // }
}

.cardProperty {
    box-shadow: rgba(17, 12, 46, 0.15) 2px 1px 0px 1px;
    padding: 0.5rem;
    margin-inline: 2.5rem;
    margin-top: 3rem;
    height: 13vh;
    border-radius: 0;
    width: 88% !important;
    display: grid;
    grid-auto-flow: column;
    // justify-content: space-between;

    @media screen and (max-width: 992px) {
        margin-inline: 11rem;
    }

    @media screen and (max-width: 768px) {
        margin-inline: 0.5rem;
        margin-top: 8rem;
        width: 97% !important;
        box-shadow: rgba(17, 12, 46, 0.15) 4px 1px 0px 5px;
    }

}

.searchProperty {
    padding: 1rem;
    width: 65rem !important;
    text-align: center;

    .form-control {
        width: 65rem !important;
        text-align: center;
    }

    @media screen and (max-width: 992px) {
        width: 20rem;
    }

    @media screen and (max-width: 892px) {
        width: 15rem;
    }

    @media screen and (max-width: 768px) {
        width: 10rem;
    }
}

select.formProperty.form-select.form-select-sm {

    height: 40px;
    margin-top: 1rem;
    width: 100%;
    margin-right: 8rem;
}

.findNow {
    background: red;
    border: none;
    height: 3rem;
    margin-top: 1rem;

    :hover,
    .active,
    focus {
        background: red;
    }
}


.propertyButton {
    margin-bottom: 1rem;
    margin-top: 1rem;
    float: right;
}

.card-body .image-container {
    position: relative;
    // display: inline-block;
}

.image-container .badge {
    position: absolute;
    top: 5px;
    font-weight: bold;
    left: 10px;
    background-color: white;
    color: black;
    padding: 8px;
    z-index: 1;
}

.heartBadge {
    position: absolute;
    top: 0px;
    font-weight: bold;
    right: 13px;
    // background-color: white;
    color: black;
    padding: 8px;
    z-index: 1;
    width: 10%;
    height: 5%;

    img {
        position: relative;
        width: 1.4vw !important;
        height: 3vh !important;

        @media only screen and (max-width: 800px) {
            width: 5vw !important;
        }
    }

    img:before {
        content: "";
        background-color: rgba(29, 41, 62, 0.6);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
    }
}



.card-body .image-container::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0px;
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
}

.housesCards {
    flex-flow: wrap;
    border-radius: 1.5rem;
    width: 97%;
    margin-bottom: 1rem;
    margin-inline: 3rem;
    margin-top: -1.5rem;
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: 992px) {
        margin-inline: 9.5rem;
    }

    @media screen and (max-width: 768px) {
        margin-inline: 0;
    }

    .slick-dots {
        bottom: 3px;

    }

    .slick-dots li button:before {

        color: white;

    }

    .house {
        width: 21.5%;
        margin-inline: 14px;
        margin-bottom: 1.5rem;
        border: 1px solid #ccc;
        border-radius: 10px;
        position: relative;
        overflow: hidden;

        @media screen and (min-width: 1800px) {
            width: 17%;
        }

        @media screen and (min-width: 2000px) {
            width: 14%;
        }

        @media screen and (min-width: 2400px) {
            width: 12%;
        }

        @media screen and (min-width: 2700px) {
            width: 10%;
        }

        @media screen and (min-width: 3300px) {
            width: 8%;
        }

        @media screen and (min-width: 3300px) {
            width: 6%;
        }

        @media screen and (max-width: 800px) {
            width: 100%;
        }


        .card-body {
            padding: 3px;
        }

        button.slick-arrow.slick-prev {
            /* background: red; */
            padding: 28px;
            z-index: 3;
            display: none;

        }

        button.slick-arrow.slick-next {
            padding: 46px;
            z-index: 3;
            display: none;

        }

        .image-container:hover button.slick-arrow.slick-prev,
        .image-container:hover button.slick-arrow.slick-next {
            display: block;
        }

        .image-container p.text-muted.float-left {
            float: left;
            font-size: 11px;
            margin-top: 0.5rem;
            margin-bottom: 0rem;

            @media screen and (max-width: 768px) {
                font-size: 9px;
            }
        }

        .houseName {
            text-align: left;
            color: black;
            font-weight: bold;

            @media screen and (max-width: 768px) {
                font-size: 13px;
            }

        }

        img {
            display: block;
            width: 100%;
            height: 13rem;
            border-radius: 10px;
            object-fit: cover;
            // @media screen and (max-width: 800px) {
            //     width: 95vw !important;
            // }
        }


    }
}



.buttonsProperties {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 768px) {
        width: 75%;
        place-items: center;
    }

    a {
        @media screen and (max-width: 768px) {
            font-size: 11px;
        }
    }

    .btn {
        @media screen and (max-width: 768px) {
            font-size: 11px;
            margin: 0;
            padding: 2px;
        }
    }
}





.display-block {
    display: block;
}

.display-none {
    display: none;
}

.modal-backdrop.show {
    opacity: 0.2;
    width: 100%;
    height: -webkit-fill-available;
}

.property-grid {
    border-radius: 0.5em;
}

.bottomButtonMap {
    border-radius: 50px;
    position: fixed;
    z-index: 3;
    bottom: 2%;
    left: 45%;
    width: 13%;
    background: rgb(230 184 0) !important;
    color: white !important;

    @media screen and (max-width: 800px) {
        left: 25%;
        width: 43%;
    }
}

.notfoundp {
    width: 90%;
    font-size: 2.5rem;
    color: lightgray;
    padding: 1rem;
}


.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 800px) {
        margin-inline: 2rem;
    }
}