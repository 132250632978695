.competitiveCarousel {
  margin-top: 2rem;

  .topViewDisplay {
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 5rem;

    @media screen and (max-width: 650px) {
      display: inline !important;
    }
  }

  .slick-slide img {
    @media screen and (max-width: 650px) {
      display: inline !important;
      width: 90%;
      margin-inline: 1rem;
      height: 70vh;
      object-fit: cover;
    }
  }

  .carouselParagraph {
    place-self: center;
    text-align: left;

    h2 {
      max-width: 600px;
      font-size: 80px;
      color: #4e6f49;
      line-height: 1.2;

      @media screen and (max-width: 650px) {
        font-size: 45px;
        margin-top: 5rem;
        padding-inline: 0.8rem
      }
    }

    p {
      max-width: 600px;
      color: #999;
      font-size: 18px;

      @media screen and (max-width: 650px) {
        padding-inline: 0.8rem
      }
    }
  }
}