.serviceAgency{
    h2{
        color: #4E6F49;
        font-size: 1.8rem;
        font-weight: bold;
    }
    .row{
        padding: 2rem;
        padding-inline: 6rem;
        @media screen and (max-width: 650px) {
            padding-inline: 2rem;
        }
        .card{
            width: 25vw;
            @media screen and (max-width: 650px) {
                width: auto;
            }
            .card-body {
                padding: 0;
            }
            p{
                width: auto;
                text-align: left;
                color: gray;
                padding: 1rem;
                @media screen and (max-width: 650px) {
                    padding: 0.5rem;
                }

                i.bi.bi-chevron-right {
                    float: right;
                }
            }
        }
        .tabLikeDataAgency{
            margin-left: 2rem;
            margin-top: 0rem;

            @media screen and (max-width: 650px) {
                margin-left: 0rem;
                margin-top: 2rem;
            }
            h2{
                font-size: 1.2rem;
                color: #4E6F49;
                text-align: left;
            }
            p{
                text-align: left;
                color: gray;
            }
            button{
                background-color: transparent;
                border-color: #4E6F49;
                color: #4E6F49;
                display: flex;
                margin-bottom: 1rem;
            }
        }
    }
}
.active-paragraph {
    background-color: #e6b800;
    color: white !important;
    padding: 0.5rem;
    cursor: pointer;
    text-shadow: 1px 2px 1px lightgray;
  }
  