.selected-property {
  // padding: 1rem;
  margin-top: 12rem;
  margin-inline: 5rem;

  @media screen and (max-width: 600px) {
    margin-inline: 0rem;
  }

  // width: 80%;
  // float: right;
  .slick-dots {
    bottom: 7px;

  }

  .selectedPropertyShowAll {
    position: relative;
    margin: 0px auto;
    left: 35%;
    width: 13%;
    z-index: 2;
    margin-top: 4.5rem;
    background-color: rgb(230, 184, 0) !important;

    @media screen and (max-width: 600px) {
      width: 55% !important;
      left: 6% !important;
      padding: 3px !important;
      border-radius: 20px;
      margin-top: 0.5rem;
    }

  }

  .ButtonsTopAmenities {
    @media screen and (max-width: 600px) {
      margin-top: 11.5rem !important;
      width: 100% !important;
    }
  }

  .bottomButtonMap {
    position: relative;
    margin: 0px auto;
    left: 6%;
    width: 13%;
    z-index: 0;
    float: initial;

    @media screen and (max-width: 600px) {
      width: 50%;
    }
  }

  .carousel-inner::after {
    display: block;
    clear: both;
    content: "";
    background-color: rgb(11 18 30 / 36%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    margin-left: 0;

    @media screen and (max-width: 600px) {
      width: 90%;
      margin-left: 18px;
    }
  }

  .slick-dots li button:before {

    color: white;

  }

  button.slick-arrow.slick-prev {
    /* background: red; */
    padding: 28px;
    z-index: 3;
    display: none;

  }

  button.slick-arrow.slick-next {
    padding: 46px;
    z-index: 3;
    display: none;

  }

  .image-container:hover button.slick-arrow.slick-prev,
  .image-container:hover button.slick-arrow.slick-next {
    display: block;
  }

  img.img-fluid {
    height: 14.5rem;
    border-radius: 10px;
    object-fit: cover
  }

  .footer .lowerFooter {
    background-color: #4E6F49;
    padding-bottom: 30px;
    padding-top: 40px;
    display: flex;
    position: inherit;
    justify-content: space-around;
  }

  .cardProperty {
    box-shadow: rgba(17, 12, 46, 0.15) 2px 1px 0px 1px;
    padding: 0.5rem;
    margin-inline: 2.5rem;
    margin-top: -3rem;
    height: 13vh;
    border-radius: 0;
    width: 93% !important;
    display: grid;
    grid-auto-flow: column;
    // justify-content: space-between;

    @media screen and (max-width: 992px) {
      margin-inline: 11rem;
    }

    @media screen and (max-width: 768px) {
      margin-inline: 0.5rem;
      margin-top: 8rem;
      width: 97% !important;
      box-shadow: rgba(17, 12, 46, 0.15) 4px 1px 0px 5px;
    }

  }

  @media screen and (max-width: 992px) {
    padding: 9rem;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    margin-left: 0;
  }

  @media screen and (max-width: 576px) {
    overflow-x: hidden;
  }

  .ImageHouseName {
    height: 15rem;
    margin-top: -17rem;

    @media screen and (max-width: 768px) {
      height: 6.5rem;
    }

    @media screen and (max-width: 576px) {
      width: 75%;
      margin-inline: 2.5rem;
    }

    // button {
    //   float: right;
    //   // width: 10%;
    //   padding: 5px;
    //   margin-top: 0.5rem;
    // }


    h2 {
      color: white;
      text-shadow: 4px 1px 0px #000000;
      font-size: 3.2rem;
      padding: 0rem;
      width: 99%;
      font-family: "Axiforma", sans-serif;
      position: relative;
      z-index: 0;
      display: block;
      text-transform: uppercase;
      // margin-inline: 13rem;

      @media screen and (max-width: 768px) {
        font-size: 1.5rem;
        width: 100%;
        margin-inline: 0rem;

      }
    }

    span.properties {
      color: white;
      padding: 1.5rem;
      margin-top: 1rem;
      display: block;
      z-index: 0;
      position: relative;
      margin-left: 0 !important;
      font-weight: 900;
      font-family: "Axiforma", sans-serif;
      text-transform: uppercase;
      text-shadow: 2px 1px 1px #000000;
    }
  }
}




// .dashboard_topheader {
//   // background-color: rgb(230, 184, 0);
//   // width: 83%;
//   // float: right;
//   .navbarDisplay {
//       background-color: white !important;
//       a.custom-link.nav-link {
//           color: black;
//           // font-size: 0.75rem;
//       }
//   }
//   a.custom-top-link.nav-link {
//       color: black;
//       font-weight: bold;
//   }



// }

.slick-list {
  margin-inline: 0rem;
}
.topSelectedProperty {
  width: 98%;
  margin: 1rem;
  border-radius: 0.5em;
  height: fit-content;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 768px) {
    margin-top: 9rem;
  }

  @media screen and (max-width: 576px) {
    margin-top: 2rem;
  }


}

// .topSelectedProperty::before {
//   content: "";
//   background-color: rgb(13 6 6 / 27%);
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   top: 0;
//   width: 87.5%;
//   margin-left: 6rem;
//   height: 15rem;
//   margin-top: 7rem;


// }

.pagination {
  display: flex;
  justify-content: space-between;

  [type=button]:not(:disabled),
  [type=reset]:not(:disabled),
  [type=submit]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
    background: rgb(230, 184, 0);
    color: white;
    border: none;
    padding: 0rem;
    border-radius: 50%;
    width: 3vw;
    height: 6vh;
    display: inline;
    place-self: center;
    font-size: 1.5rem;
    font-family: cursive;

    @media screen and (max-width: 600px) {
      width: 12vw;
    }
  }

  [type=button],
  [type=reset],
  [type=submit],
  button {
    -webkit-appearance: button;
    border: none;
    background: rgb(230 184 0 / 41%);
    color: white;
    padding: 0rem;
    border-radius: 50%;
    width: 3vw;
    height: 6vh;
    display: inline;
    place-self: center;
    font-size: 1.5rem;
    font-family: cursive;

    @media screen and (max-width: 600px) {
      width: 12vw;
    }

  }
}

.amenityText {
  color: #4E6F49;
  font-weight: bold;
  font-family: sans-serif;
}

.CardHouseName {
  box-shadow: rgba(12, 12, 46, 0.15) 9px 2px 24px 1px;
  padding: 1rem;
  display: flex;
  justify-content: start;
  flex-flow: wrap;
  border-radius: none;
  border-radius: 0px;
  margin-top: -3rem;
  height: 5rem;
  width: 95%;
  margin-inline: 1.5rem;

  .searchProperty {
    padding: 1rem;
    width: 40%;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    height: auto;
    margin-top: 0;
    margin-inline: 0;
    width: auto;

  }

  @media screen and (max-width: 576px) {
    display: block;

  }
}

select.formProperty.form-select.form-select-sm {
  height: 40px;
  margin-top: 1rem;
  width: 100%;
  margin-right: 0rem;
}

.formSelectedProperty {
  width: 28%;
  margin-inline: 1rem;
  margin-right: 0rem;

  @media screen and (max-width: 768px) {
    width: auto;
    margin-bottom: 1rem;

  }
}

.metricsCards {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;

  @media screen and (max-width: 576px) {
    margin-top: 4rem;
    display: block;
  }

  .card {
    width: 20%;
    border: none;
    border-radius: 30px;

    @media screen and (max-width: 576px) {
      width: 30%;
      margin-bottom: 1.5rem;
      margin-inline: 1rem;
    }
  }

  .card-body {
    display: flex;

    .float-left {
      text-align-last: left;

      .d-flex.mt-2 {
        p {
          font-size: 25px;
        }

      }
    }

    img {
      padding: 0.7rem;
      background-color: white;
      border-radius: 102%;
      align-self: center;
      margin-inline: 1rem;
      font-size: 2rem;
      width: 25%;
    }

  }

}



.formCalendar {
  margin-bottom: 1rem;
  margin-top: 1rem;
  float: right;
  width: 25%;
}


.calendar {
  width: 100%;
  margin-top: 2rem;
}

.topProperty {
  margin-top: 3rem;
  box-shadow: rgba(12, 12, 46, 0.15) 0px 0px 6px 2px;
  // height: 100%;
  padding: 1rem;

  .card-header {
    font-weight: bold;
  }

  .card-body .row .col-md-6 span {
    float: left;
    margin-bottom: 1rem;
    // font-size: 16.5px;
    font-size: inherit;
  }

}

.d-flex.col-md-9.col-sm-6 {
  flex-wrap: wrap;
}

.buttonsProperty {
  display: flex;

  .OccupiedBtn {
    border-radius: 20px;
    margin-inline: 0.5rem;
  }
}

.house {
  width: 23%;

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  @media screen and (min-width: 1580px) {
    width: 19%;
  }

  @media screen and (min-width: 1900px) {
    width: 13%;
  }



  margin-inline: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin-top: 3rem;

  .houseUnit {
    display: block;
    width: 100%;
    height: 15rem;
    object-fit: cover;
    border-radius: 0.5em;
  }

  p.text-muted.float-left {
    float: left;
    font-size: 21px;
    line-height: 2;
    margin-bottom: 0;
  }

  p.houseName {
    clear: left;
    color: black;
    text-align-last: left;
    // margin-left: 5px;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
  }

  p.houseTitle {
    color: #95a5a6;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 25px !important;

  }

}

.noHouse {
  margin-top: 3rem;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 2rem;
  color: #95a5a6;
}

.addHouse {
  text-align-last: right;
}

// loading designs 
.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #00bfff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: loader-rotate 1s infinite linear;
  margin-bottom: 10px;
}

@keyframes loader-rotate {
  to {
    transform: rotate(360deg);
  }
}

.amenitiesDiv {
  display: flex;
  justify-content: space-between;

  i {
    border-radius: 50%;
  }

  margin-bottom: 0.5rem;
}

img.propertyCarousel {
  width: 87vw;
  height: 35vh;
  object-fit: cover;
  border-radius: 15px;
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 15px;
}

.carousel-item::before {
  content: "";
  background-color: rgba(29, 41, 62, 0.6);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;

  @media screen and (max-width: 600px) {
    background-color: transparent;
  }
}

.carousel-container {
  position: relative;
  width: 100%;
  /* Adjust the width as needed */
  max-width: 800px;
  /* Set a maximum width as needed */
  margin: 0 auto;
  /* Center the carousel horizontally */
  overflow: hidden;
  cursor: grab;
  /* Change the cursor to a grab hand */
  touch-action: pan-y;
  /* Allow vertical touch scrolling */

  /* Define a transition for smooth hover effect */
  transition: transform 0.3s ease-in-out;
}

.carousel-container:hover {
  cursor: grabbing;
  /* Change cursor during drag */
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.starImageSelectedProperty {
  //  style={{ width: "8%", height: "30%" }}
  width: 8%;
  height: 30%;

  @media screen and (max-width: 600px) {
    width: 6%;

  }
}