.homepageSearch {
    position: relative;
    input, input:focus.input-range_input {
        padding-left: 1%;
        width: 18%;
    }
    select {
        height: auto;
        border-radius: 8px;
        border-color: lightgrey;
        padding: 1rem;
        color: #747272;
        font-size: 13px;
        width:20vw;
        @media screen and (max-width: 800px) {
            width: 100%;
            margin-bottom: 0.5rem;
            height: auto;

        }
    }

    li.nav-item {
        border: none;
        background: white;
        color: black !important;
        width: 10vw;
        border-radius: 10px;
        border: none;
        outline: none;
        margin-inline: 2rem;
        @media screen and (max-width: 800px) {
            width: 20%;
        }
    }

    .nav-tabs .nav-link.active {
        background: rgb(230, 184, 0);
        color: white;
        border-radius: 10px;
        border: none;
        outline: none;
        position: relative;
        @media screen and (max-width: 800px) {
            width: 100%;
        }
    }

    .nav-tabs .nav-link.active::after {
        content: '';
        width: 2vw;
        height: 1vh;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 15px solid rgb(230, 184, 0);
        position: absolute;
        bottom: -10px;
        left: 50%; 
        transform: translateX(-50%); 
      }

    ul.mb-3.nav.nav-tabs {
        border: none;
        place-content: center;
    }

    .nav-link {
        color: black;
        border: 0px solid;
        place-content:center;

        :hover {
            border: none;
        }
    }

    button {
        width: 10%;
        border: none;
        width: 10vw;
        @media screen and (max-width: 800px) {
            width: 100%;
            margin-bottom: 0.5rem;

        }
    }
    .searchButtonHome {
        background-color: rgb(230, 184, 0);
        width: 10vw;
        height: 7vh;
        @media screen and (max-width: 800px) {
            width: 100%;
            margin-bottom: 0.5rem;
            height: 5vh;

        }
    }

    .card {
        // background-color: transparent;
        padding: 0.5rem;
        background-color: hsla(0, 0%, 100%, .15);
        border-radius: 8px;

        .card-body {
            padding: 1.5rem;
            border-radius: 8px;
            background-color: white;
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 800px) {
                display: block;

                input {
                    width: 100%;
                    margin-bottom: 0.5rem;

                }
            }
        }
    }

    
}
.filterButtonDisplay{
    width: 30%;
    @media screen and (max-width: 800px) {
        width: fit-content;
    }
}
.currency-label {
    position: relative;
    display: flex;
    align-self: center;
    margin-right: 0.5rem;
}

.rentFilterSearchDisplay{
    width: 95%;
    margin-inline: 0.2rem;
    place-content: center;
    display: flex;
    @media screen and (max-width: 800px) {
        display: grid;
        width: 100%;
        justify-content: left;

    }
}
