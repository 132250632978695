.responsive-menu {
  // position: fixed;
  // top:0;

}

.leftSideBar {
  display: flex;

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.loginAirbnb {
  @media screen and (max-width: 600px) {
    display: block;
    width: 20%;
    place-self: center;
  }
}

.topNavbarNavigation {
  @media screen and (max-width: 600px) {
    // display: flow !important;
    display: block !important;

    .navbar-brand {
      img {
        width: 75%;
      }
    }

  }
}

.navbarDisplay {
  background-color: transparent !important;
  color: white;

  a.custom-link.nav-link {
    color: white;
  }

}

.custom-link {
  color: white;
  font-weight: bold;
  .imageLogoDisplayWhite{
    display: inherit !important;
    // background: rgb(29 41 62 / 53%);
    margin-top: -1rem;
    border-radius: 0px 0px 10px 10px;
  }
  .imageLogoDisplay{
    display: none;
  }

}

// .active{
//   color: #4E6F49;
// }

.custom-link:hover {
  color: #4E6F49;
}

.custom-link:active {
  color: #4E6F49;
}

.loginForm {
  h5 {
    padding: 0.2rem;
  }

  label {
    color: gray;
    position: absolute;
    top: 21%;
    left: 15.5%;
    font-size: 12px;
    margin-top: 0rem;
  }

}

.loginText {
  left: 35%;
  position: absolute;
  top: 5%;
}


.loginAirbnb {
  .loginIcon {
    background-color: rgb(230, 184, 0);
    color: white;
    padding-right: 0px;
    border: none;
    display: flex;
    align-items: center;
    border-radius: 20px;
    @media screen and (max-width: 600px) {
      height: fit-content;
  }

    span.bi.bi-list.me-2 {
      @media screen and (max-width: 600px) {
        margin-left: 1rem;
      }
    } 
  }

  .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    color: transparent;
  }
}