.competitive {
    .competitiveDesktop{
        display: block;
        @media screen and (max-width: 650px) {
            display: none;

        }   
    }
    .competitiveMobile{
        display: none;
        @media screen and (max-width: 650px) {
            display: block;

        }  
    }
    .firstrow {
        width: 95%;
        padding: 3rem !important;

        @media screen and (max-width: 650px) {
            padding: 0.5rem !important;
            width: 100%;

        }
        .imageOne {
            img {
                width: 40vw;
                height: 90vh;
                object-fit: cover;
                @media screen and (max-width: 650px) {
                    width: 90vw;
                    height: 45vh;

                }
            }
        }

        .paragraphOne {
            text-align: left;
            margin-top: 2rem;

            img {
                display: block;
                width: 15%;
                border: 1px solid #cbcbcb94;
                padding: 1rem;
                border-radius: 20px;
                margin-bottom: 30px;
                @media screen and (max-width: 650px) {
                    width: 25%;
        
                }   
            }

            span {
                text-transform: uppercase;
                font-style: normal;
                letter-spacing: 4px;
                font-weight: 400;
                font-size: 14px;
                color: #777;
                line-height: 3;
            }

            h2 {
                font-size: 42px;
                color: #4E6F49;
                line-height: 2;

            }

            p {
                color: #777;

            }
        }

    }
    .secondPart{
        margin-left:2rem;
        @media screen and (max-width: 650px) {
            margin-left:0rem;
            .paragraphOne{
                margin-top: 0rem;
            }

        }

    }
}