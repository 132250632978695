.profile {

    // height: 125vh;


    form {
        padding: 4.5rem;
        box-shadow: 1px 15px 20px 1px lightgray;
        border: 0;
        width: 50%;
        margin: 0 auto;

        @media screen and (max-width: 800px) {
            width: 95%;
            padding: 1.5rem;

        }

        .form-label {
            margin-bottom: .5rem;
            float: left;
        }
    }
}

a.addHouse {
    text-decoration: none;
    align-self: center;
    color: black;
    margin-top: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    display: -webkit-box;

    i.bi.bi-arrow-left {
        background: gray;
        padding: 0.5rem;
        border-radius: 50%;
        color: white;

    }
}

.ProfileInputGroup {
    width: 140%;
    margin-left: -9rem;

    @media screen and (max-width: 800px) {
        width: 140%;
        margin-left: -5rem;

    }
}

.offcanvasDisplayProfile.offcanvas.offcanvas-start.show {
    width: 60vw !important;

    @media screen and (max-width: 800px) {
        width: 100vw !important;
    }
}

.offcanvas-backdrop.show {
    opacity: 0;
}

.offcanvas-open {
    overflow: hidden;
}

body.modal-open {
    overflow: auto !important;
}
body{
    overflow: auto !important;
}