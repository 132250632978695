.offcanvas-body {
    label {
        float: left;
        color: rgb(25, 135, 84);
    }

    input {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        width: 100% !important;
    }
}