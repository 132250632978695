body {
  margin: 0;
  font-family: "Axiforma", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:"Axiforma", sans-serif !important;
}
:root{
  --primary:rgb(230, 184, 0),
  --secondary:#4E6F49,
}