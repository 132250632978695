.homepage {
    // background-image: url('../images/homepage.jpg');
    height: 110vh;
    position: relative;
}

.homepage::before {
    content: "";
    background-color: rgba(29, 41, 62, 0.6);
    // background-color: rgb(179 188 203 / 60%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;


}

.homePageTexts {
    padding-top: 13rem;
    color: white;
    position: relative;
    @media screen and (max-width: 800px) {
        padding-top: 5rem;


    }

    h2 {
        font-weight: bolder;
        font-size: 3rem;
    }

    p {
        font-size: 1rem;
    }

}

input,
input:focus {
    padding: 5px;
    outline-color: #8181f5;
    text-align: start;
    padding-left: 30px;
    font-size: 15px;
    background-color: #fff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    font-size: 14px;
    color: #484848;
    line-height: 1.2;
    height: 50px;
    width: 100%;

}

.advanceButtonSearch {
    border: none;
    background-color: transparent;
    color: black;
    height: 50px;

}

// .dashboard_topheader {
//     // background-color: rgb(230, 184, 0);
//     // width: 83%;
//     // float: right;
//     .navbarDisplay {
//         background-color: white !important;
//         a.custom-link.nav-link {
//             color: black;
//             // font-size: 0.75rem;
//         }
//     }
//     a.custom-top-link.nav-link {
//         color: black;
//         font-weight: bold;
//     }
//     // nav.custom-navbar.responsive-menu.bg-white.fixed-top.navbar.navbar-expand-lg.navbar-light {
//     //     display: none;
//     // }
//     img{
//         width: 50%;
//     }


// }
/* Add this CSS to your stylesheet or a style tag in your component */
.custom-dropdown-toggle {
    background-color: #4E6F49;
    color: white;

}

.custom-dropdown-toggle:focus,
.custom-dropdown-toggle:hover {
    background-color: #4E6F49;
    /* Change to green when clicked or hovered */
}

//   left navigation display
.leftSideBar {
    margin-right: 15rem;

}
.mapLoactionDisplay{
    width: 88% !important;
    margin-inline: 6rem;
    margin-top: 2rem;
    height: 100vh !important;
    @media screen and (max-width: 800px) {
        margin-inline: 2rem;
        margin-top: 4rem;
        width: 85% !important;

    }
}

.mapFooter {
    display: inherit;
    @media screen and (max-width: 800px) {
        display: none;

    }
    .footer {
        position: absolute;
        width: 100%;
        bottom: -40%;
    }
}

.input-range {
    height: 1rem;
    position: relative;
    width: 95% !important;
    margin: 0 auto !important;
}
input, input:focus.input-range_input {
    padding-left: 32px;
}
.currency-label-first{
    position: absolute;
    top: 26%;
    font-size: 0.8rem;
    left: 18%;
    
}
.badge_rent {
    background: white;
    position: absolute;
    top: 65%;
    right: 2%;
    color: black;
    font-size: 0.7rem;
    padding: 0.3rem;
    border-radius: 5px;
    font-weight: bold;
}
.houseRentP {
    color: black;
    float: left;
    margin-top: -1.8rem;
    font-size:1rem;
    margin-bottom: 0.2rem;
    font-weight: bold;
}
.input-icons i {
    position: absolute;
    margin-left: -0.5rem;
  }
  
  .input-icons {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 18px;
  }
  
  .icon {
    padding: 15px;
    color: grey;
    min-width: 50px;
    text-align: center;
  }
  .house-map.card {
    width: 30vw;
    height: -moz-fit-content;
    height: fit-content;
    flex-flow: wrap-reverse;
    border-radius: 10px;
    margin-top: 1rem;
    margin-inline: 1rem;
    @media only screen and (max-width: 600px) {
        width: 75vw;
    }

    .image-house-map{
        width: 27vw !important;
        height: 35vh;
        object-fit: cover;
        border-radius: 10px;
        @media only screen and (max-width: 600px) {
            width: 70vw;
        }
    }
}
.gm-ui-hover-effect>span {
    background-color: #000;
    width: 28px !important;
    height: 23px !important;
    margin: 0px !important;
}