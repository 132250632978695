.bookings {
    margin-top: 10rem;
    display: flex;
    flex-wrap: wrap;
    h2 {
        margin-top: 12rem;
        color: green;
        font-weight: bold;
        font-size: 3rem;
    }
    .filterCard {
        height: 105vh;
        position: fixed;
        width: 30vw;
        margin-left: 1rem;

        h2 {
            color: orange;
            font-weight: bold;
            font-size: 1.2rem;
            margin-top: 0.5rem;
            text-transform: uppercase;
            border-bottom: 1px solid lightgray;
            padding: 1rem;
        }
    }

    .bookingCards {
        width: 27vw;
        margin-inline: 1rem;
        box-shadow: 1px 4px 5px 1px lightgray;
        margin-bottom: 2rem;
        border: 0;
        padding: 0.5rem;
        border-radius: 10px;

        .houseUnitImage {
            height: 30vh;
            width: 20vw;
            border-radius: 10px;
        }

        .topData {
            margin-top: 1rem;
            text-align: right;
            font-size: 20px;
            font-weight: bolder;
            color: #198754;
        }
    }

}