.all-photos {
    padding-top: 8rem;
    margin: 0 auto;
    width: 60%;

    @media screen and (max-width: 650px) {
        width: 100%;
    }

    .row {
        .accordion {
            margin-left: 20 !important;

            @media screen and (max-width: 650px) {
                margin-left: 0 !important
            }
        }
        button.accordion-button {
            width:15vw !important;

            @media screen and (max-width: 650px) {
                width:30vw !important;
            }
        }

        .col-md-12 {

            // height: fit-content;
            img {
                height: 75%;
                width: 101.5%;
                object-fit: cover;

            }
        }

        .col-md-6 {
            img {
                height: 75%;
                width: 103%;
                object-fit: cover;

            }

            // height: fit-content;
        }

    }
}

.topNavigation {
    position: fixed;
    font-size: 2rem;
    padding: 1.2rem;
    border-radius: 50%;
    color: black;

    @media screen and (max-width: 600px) {
        padding-top: 1.2rem;
        z-index: 100000;
    }
}