.whyus {
    padding: 2rem;

    .rowWhyUs {
        .card {
            border: none;
            height: 40vh;
            @media screen and (max-width: 800px) {
                margin-bottom: 1rem;

            }
            .card-body {
                margin-top: 2rem;

                h2 {
                    font-size: 1.2rem;
                    margin-top: 2rem;
                }

                p {
                    font-size: 0.8rem;
                }

                span {
                    i {
                        padding: 1rem;
                        background-color: rgb(230 184 0 / 23%);
                        border-radius: 50%;
                        color: rgb(230, 184, 0);
                        font-size: 2rem;
                    }
                }


            }

            .card-body:hover {
                span {
                    i {
                        padding: 2rem;
                        background-color: rgb(230, 184, 0);
                        border-radius: 50%;
                        color: white;
                    }
                }
            }
        }
    }

    .rowArticles {
        .card {
            height: 60vh;
            border: none;
            margin-bottom: 2rem;
            @media screen and (max-width: 800px) {
                margin-bottom: 5rem;

            }

            .card-body {
                h2 {
                    font-weight: bold;
                    font-size: 0.8rem;
                    text-align: left;
                    color: rgb(230, 184, 0);
                    margin-top: 0.5rem;
                }

                p {
                    font-weight: normal;
                    text-align: left;
                }

                img {
                    width: -webkit-fill-available;
                    height: 27vh;
                    object-fit: cover;
                    border-radius: 10px;
                    @media screen and (max-width: 800px) {
                        width: -webkit-fill-available;
                        object-fit: cover;
                        border-radius: 10px;
                    }
                }
            }

            .card-footer {
                display: flex;
                justify-content: space-between;
                background-color: white;
                @media screen and (max-width: 800px) {
                    display: block;
                }
                .footerWhyUs {
                    display: flex;

                    img {
                        border-radius: 50%;
                        width: 3vw;
                        margin-right: 0.5rem;

                        @media screen and (max-width: 800px) {
                            width: 15vw;
                        }
                    }

                }
                p {
                    margin-top: 0.5rem;
                    align-self: center;
                    display: block;

                    @media screen and (max-width: 800px) {
                        display: flex;
                    }
                }
            }

        }

    }

}