.real-agent{
    background-color: rgb(230, 184, 0);
    color: white;
    display: flex;
    justify-content: space-around;
    padding: 40px;

    button{
        background-color: #4E6F49;
        border: 1px solid #4E6F49;
        height: 8vh;
    }
}