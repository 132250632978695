.favourites {
    padding: 6rem;
    
    @media only screen and (min-width: 1700px) {
        padding: 5rem;
    }

    h2 {
        text-transform: uppercase;
        font-size: 1.5rem;
        color: #4E6F49;
        font-weight: bolder;
    }

    h3 {
        text-align: left;
        font-size: 1.2rem;
        text-transform: capitalize;
        background: #cfd0cf78;
        padding: 1rem;
        color: white;
        border-radius: 15px;

    }

    .favouriteDivs {
        display: flex;

        .card {
            width: 25vw;
            height: 45vh;
            margin-inline: 1rem;
            margin-top: 0;
            @media only screen and (min-width: 1700px) {
                width: 65vw;
                height: 50vh;

            }
        
            .card-body{
                img{
                        width: 100%;
                        display: inline-block;
                        height: 25vh;
                        object-fit: cover;
                    
                }
                .heartBadge img {
                    position: relative;
                    width: 1.4vw !important;
                    height: 3vh !important;
                    object-fit: contain !important;
                }
                a.text-decoration-none {
                    display: flex;
                    color: rgb(230 184 0);
                    text-decoration: underline !important;
                    width: 100%;
                }
            }
        }
    }
}
h3.activeFavourite {
    background: #67696778;
    padding: 1rem;
    color: white;
    border-radius: 15px;
    text-transform: capitalize;
}