.competitivefaq {
    .topRow.row {
        padding: 4.5rem;
        text-align: left;
        @media screen and (max-width: 650px) {
            padding: 1.5rem;

        }  
        h2{
            font-size: 1.5rem;
            font-weight: bold;
        }
        i{
            font-size: 3rem;
            color:rgb(230, 184, 0);
        }
        span{
            color: gray;
        }
    }
    h2 {
        color: #4E6F49;
        font-weight: 600;
        line-height: 1.5;
        margin: 0 0 30px 0;
        font-family: 'Poppins', sans-serif;
    }
    .faqContainer{
        padding-right: 3rem!important;
        padding-left: 3rem!important;
        @media screen and (max-width: 650px) {
            padding-right: 0rem!important;
            padding-left: 1rem!important;

        }  
    
      .row{
        padding-inline: 4rem;
        text-align: left;
        margin-bottom: 2rem;
        @media screen and (max-width: 650px) {
            padding-inline: 0.5rem;


        }  
        h3{
            font-size: 1rem;
            color: #4E6F49;
            line-height: 3;
            font-weight: bold;

        }
        p{
            font-size: 0.8rem;
            color: #4E6F49;

        }
        a{
            text-decoration: none;
            color:rgb(230, 184, 0);
            border-bottom-style: dotted;
            font-size: 0.8rem;
        }
      }
    }
}