.singleBlog{
    margin-top:6rem;
    display: flex;
    
    .card{
        width: 70%;
        box-shadow: 1px 7px 13px 7px lightgray;
        // margin-left: 9.5rem;
        margin: 0 auto;
        text-align: left;
        padding: 2rem;
        border: 0;
        margin-top: 1rem;
        margin-bottom: 2rem;
        @media screen and (max-width: 800px) {
            width: 97%;

        }
        button{
            width: 15%;
            display: flex;
            position: relative;
            right: -85%;
            @media screen and (max-width: 800px) {
                width: 30%;
                right: -75%;

            }
        }
        .dateUserBlog{
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 800px) {
                display: block;


            }
        }
        .coverImage{
            object-fit: cover;
            height: 60vh;
            width: 100%;
            margin-bottom: 2rem;
        }
        .row{
            p{
                font-size: 0.8rem;
                color: #4E6F49;
            }
        }
    }
}